import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import {FaArrowLeft} from 'react-icons/fa';
import PostTags from "../components/PostTags/PostTags";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import Hero from "../components/Modules/Hero";
import Img from 'gatsby-image';

export default class PostTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { slug } = pageContext;
    const postNode = data.markdownRemark;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }
    const onClick = e => {
      e.preventDefault()
      history.back()
    }
    return (
      <Layout>
        <div>
          <Helmet>
            <title>{`${post.title}`}</title>
          </Helmet>
          <SEO postPath={slug} postNode={postNode} postSEO />
          <div className="post flex column center">
          <Hero
            fluid={post.img.childImageSharp.fluid}
            posY="50%"
            posX="60%"
            height="60vh"
            width="100%"
            />
          <div className="backArrow">
            <FaArrowLeft size={32} onClick={onClick} />
          </div>
          <div className="article basePad" >
            <div className="titleAndLogo">
              <div>
                <h1>{post.companytitle ? post.companytitle : post.title}</h1>
                <h3>{post.addressline1}<br/>{post.addressline2}<br/>{post.addressline3}</h3>
                <p>{post.companydescription ? post.companydescription : ''}</p>
                </div>
              {post.logo ? 
                <Img fluid={post.logo.childImageSharp.fluid} /> : ''
              }
              </div>
            <div dangerouslySetInnerHTML={{ __html: postNode.html }} className="articleContent"/>
          </div>
        </div>
          {
            post.contacts[0].name === 'nocontact' ?
            null :

            <div className="flex contacts center basePad column">
              { post.contacts.map((contact, i) => {
                let img = contact.img !== null ? contact.img : null;
                return (
                    <div key={contact.name} className="contactSheet center flex column">
                        { img !== null ? <Img fluid={contact.img.childImageSharp.fluid} /> : ''}
                        <h3>{contact.name}</h3>
                        <p style={{color: 'grey'}}>{contact.title}</p>
                        <p>{contact.email}</p>
                        <p>tel: {contact.tlf}</p>
                        {contact.landline ? <p>land line: {contact.landline}</p> : ''}
                        {
                          contact.linkedIn !== '' ?
                          <a href={contact.linkedIn} target="_blank"><p>To LinkedIn</p></a> :
                          null
                        }
                      </div>
                )
              })}
            </div>
          }
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        companytitle
        companydescription
        img {
          childImageSharp {
              fluid(maxWidth: 1440, quality: 80) {
                src
                ...GatsbyImageSharpFluid
            }
          }
        }
        logo {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        date
        category
        tags
        addressline1
        addressline2
        addressline3
        contacts {
          name
          title
          email
          tlf
          landline
          linkedIn
          img {
            childImageSharp {
              fluid(quality: 90) {
                src
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      fields {
        slug
        date
      }
    }
  }
`;
